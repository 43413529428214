import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import useGetCaseAttribute from "../customHooks/useGetCaseAttributes";
import useGetCaseWorkers from "../customHooks/useGetCaseWorkers";
import OverviewSettings from "./OverviewSettings";
import axios from "axios";
import FileSaver from 'file-saver';

import { IconButton, Alert, Snackbar, LinearProgress, Typography } from "@mui/material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import Button from "@mui/material/Button";

import "../../styles/styleOverviewActionContainer.css";

function OverviewActionContainer({ selectedRows, onFilterApply }) {
  const [alertOpenUpdate, setAlertOpenUpdate] = useState(false);
  const [alertOpenUpload, setAlertOpenUpload] = useState(false);
  const [alertOpenDuplicate, setAlertOpenDuplicate] = useState(false);
  const [uploading, setUploading] = useState(false); // State to manage upload progress visibility
  const [uploadProgress, setUploadProgress] = useState(0); // State to store upload progress percentage
  const [file, setFile] = useState(null); // State for file
  const { actionStatusOptions, actionCategoryOptions, actionLabelOptions } = useGetCaseAttribute();
  const { actionAssigneeOptions } = useGetCaseWorkers();

  const [actionStatusSelected, setActionStatusSelected] = useState(actionStatusOptions[0]?.id || "");
  const [actionCategorySelected, setActionCategorySelected] = useState(actionCategoryOptions[0]?.id || "");
  const [actionLabelSelected, setActionLabelSelected] = useState([]);
  const [actionAssigneeSelected, setActionAssigneeSelected] = useState(actionAssigneeOptions[0]?.id || "");

  const handleActionStatusChange = (event) => {
    setActionStatusSelected(event.target.value);
  };

  const handleActionCategoryChange = (event) => {
    setActionCategorySelected(event.target.value);
  };

  const handleActionLabelChange = (event) => {
    setActionLabelSelected(event.target.value);
  };

  const handleActionAssigneeChange = (event) => {
    setActionAssigneeSelected(event.target.value);
  };

  const handleFilter = async () => {
    if (selectedRows.length > 0) {
      const requestBody = {
        caseIds: selectedRows.map((row) => row.id),
        statusCode: actionStatusSelected ? parseInt(actionStatusSelected) : "",
        categoryCode: actionCategorySelected ? parseInt(actionCategorySelected) : "",
        labelCodes: actionLabelSelected.length > 0 ? actionLabelSelected.map((label) => parseInt(label)) : [],
        caseWorkerUserId: actionAssigneeSelected ? parseInt(actionAssigneeSelected) : "",
      };

      try {
        const response = await axios.put("/overview/update/cases", requestBody, {
          headers: {
            withCredentials: true,
          },
        });
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        // Clear the selections
        setActionStatusSelected("");
        setActionCategorySelected("");
        setActionLabelSelected([]);
        setActionAssigneeSelected("");
        setAlertOpenUpdate(true); // Show update success alert
        if (onFilterApply) onFilterApply(); // Call callback to refresh cases
      } catch (error) {
        console.error("Error fetching table rows:", error);
      }
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Save selected file in state
      await handleFileUpload(selectedFile); // Automatically upload file once selected
    }
  };

  const handleFileUpload = async (fileToUpload) => {
    setUploading(true); // Show the progress bar
    setUploadProgress(0); // Reset progress to 0 before starting the upload

    const formData = new FormData();
    formData.append("file", fileToUpload);

    try {
      const response = await axios.post("/overview/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          withCredentials: true,
        },
        responseType: "blob", // Important: expect a binary response (Excel file)
        onUploadProgress: (progressEvent) => {
          // Calculate and set the progress percentage
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });

      // Check if the server response status is successful
      if (response.status === 201 || response.status === 200) {
        setUploadProgress(100); // Set progress to 100% after successful upload
        setAlertOpenUpload(true); // Show success alert

        // Check if the response contains an Excel file for duplicates
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.includes('attachment')) {
          // Extract filename from the Content-Disposition header (if provided)
          const fileName = contentDisposition.split('filename=')[1]?.replace(/['"]/g, '') || 'duplicates.xls';

          // Trigger file download using FileSaver.js
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          FileSaver.saveAs(blob, fileName);
          setAlertOpenDuplicate(true);
        }
      } else {
        throw new Error(`Upload failed with status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Optionally handle errors here, e.g., show error alert
      setUploadProgress(0); // Optionally reset progress on error
    } finally {
      // Hide the progress bar once the upload process is complete
      setUploading(false);
    }

    // Call callback to refresh cases
    if (onFilterApply) onFilterApply();
  };

  return (
    <div className="divOverviewActionContainer">
      <div className="acComboBox">
        {/* Status selection */}
        <FormControl className="acFormControl">
          <InputLabel>Status</InputLabel>
          <Select value={actionStatusSelected} onChange={handleActionStatusChange}>
            <MenuItem id="acSelectBoxItems" value="">
              <em>Bitte Status auswählen</em>
            </MenuItem>
            {actionStatusOptions.map((actionStatusOption) => (
              <MenuItem id="acSelectBoxItems" key={actionStatusOption.id} value={actionStatusOption.id}>
                {actionStatusOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Category selection */}
        <FormControl className="acFormControl">
          <InputLabel>Kategorien</InputLabel>
          <Select value={actionCategorySelected} onChange={handleActionCategoryChange}>
            <MenuItem id="acSelectBoxItems" value="">
              <em>Bitte Kategorie auswählen</em>
            </MenuItem>
            {actionCategoryOptions.map((actionCategoryOption) => (
              <MenuItem id="acSelectBoxItems" key={actionCategoryOption.id} value={actionCategoryOption.id}>
                {actionCategoryOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Labels selection */}
        <FormControl className="acFormControl">
          <InputLabel>Labels</InputLabel>
          <Select multiple value={actionLabelSelected} onChange={handleActionLabelChange} renderValue={(selected) => selected.map((label) => actionLabelOptions.find((option) => option.id === label)?.text || label).join(", ")}>
            <MenuItem id="acSelectBoxItems" value="">
              <em>Bitte Label auswählen</em>
            </MenuItem>
            {actionLabelOptions.map((actionLabelOption) => (
              <MenuItem id="acSelectBoxItems" key={actionLabelOption.id} value={actionLabelOption.id}>
                {actionLabelOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Assignee selection */}
        <FormControl className="acFormControl">
          <InputLabel>Assignee</InputLabel>
          <Select value={actionAssigneeSelected} onChange={handleActionAssigneeChange}>
            <MenuItem id="acSelectBoxItems" value="">
              <em>Bitte Verantwortlichen auswählen</em>
            </MenuItem>
            {actionAssigneeOptions.map((actionAssigneeOption) => (
              <MenuItem id="acSelectBoxItems" key={actionAssigneeOption.id} value={actionAssigneeOption.id}>
                {actionAssigneeOption.firstName} {actionAssigneeOption.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Apply filter button */}
        <Button className="cmdSetAction" size="small" startIcon={<ChecklistOutlinedIcon className="cmdSetActionIcon" />} onClick={handleFilter}>
          Aktion anwenden
        </Button>

        {/* Hidden file input */}
        <input className="uploadExcel" type="file" id="fileInput" onChange={handleFileChange} />

        {/* Upload progress container */}
        {uploading && (
          <div className="excelLoadingContainer">
            <div className="excelProgressBarWrapper">
              <LinearProgress className="excelProgressBar" variant="determinate" value={uploadProgress} />
            </div>
            <Typography className="excelProgressText">{`Datei wird hochgeladen ${Math.round(uploadProgress)}%`}</Typography>
          </div>
        )}

        {/* Success Snackbar for update */}
        <Snackbar open={alertOpenUpdate} autoHideDuration={6000} onClose={() => setAlertOpenUpdate(false)}>
          <Alert onClose={() => setAlertOpenUpdate(false)} severity="success">
            Aktualisierung erfolgreich angewendet!
          </Alert>
        </Snackbar>

        {/* Success Snackbar for upload */}
        <Snackbar open={alertOpenUpload} autoHideDuration={6000} onClose={() => setAlertOpenUpload(false)}>
          <Alert onClose={() => setAlertOpenUpload(false)} severity="success">
            Datei erfolgreich hochgeladen!
          </Alert>
        </Snackbar>

        {/* Success Snackbar for duplicates */}
        <Snackbar open={alertOpenDuplicate} autoHideDuration={20000} onClose={() => setAlertOpenDuplicate(false)}>
          <Alert onClose={() => setAlertOpenDuplicate(false)} severity="success">
            Beim Hochladen des Dokuments wurden doppelte Einträge gefunden!
          </Alert>
        </Snackbar>
      </div>

      <div className="acButtons">
        <IconButton disableRipple>
          <PictureAsPdfOutlinedIcon className="pdfIcon" />
        </IconButton>
        <IconButton disableRipple>
          <InsertDriveFileOutlinedIcon className="rtfIcon" />
        </IconButton>
        <IconButton style={{ display: 'none' }} disableRipple onClick={() => document.getElementById("fileInput").click()}>
          <UploadFileOutlinedIcon className="uploadIcon" />
        </IconButton>
        <OverviewSettings />
      </div>
    </div>
  );
}

export default OverviewActionContainer;
